import React from "react"
import { Main, Box } from "grommet"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"
import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
  * {
    @import url('https://fonts.googleapis.com/css?family=Raleway:200&display=swap');
    font-family: 'Raleway', sans-serif;
  }
`
const Layout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <>
      <GlobalStyle />
      <Box height="100vh">
        <Main align="center" justify="center">
          {children}
        </Main>
      </Box>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
